import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy on technology equipment disposal.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`Technology equipment often contains parts which cannot simply be thrown
away. Proper disposal of equipment is both environmentally responsible
and often required by law. In addition, hard drives, USB drives, CD-ROMs
and other storage media contain various kinds of Data Migrators data,
some of which is considered sensitive. In order to protect our
constituent’s data, all storage mediums must be properly erased before
being disposed of. However, simply deleting or even formatting data is
not considered sufficient. When deleting files or formatting a device,
data is marked for deletion, but is still accessible until being
overwritten by a new file. Therefore, special tools must be used to
securely erase data prior to equipment disposal.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`The purpose of this policy it to define the guidelines for the disposal
of technology equipment and components owned by Data Migrators.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy applies to any computer/technology equipment or peripheral
devices that are no longer needed within Data Migrators including, but
not limited to the following:  personal computers, servers, hard drives,
laptops, mainframes, smart phones, or handheld computers ( i.e., Windows
Mobile, iOS or Android-based devices), peripherals (i.e., keyboards,
mice, speakers), printers, scanners, typewriters, compact and floppy
discs, portable storage devices (i.e., USB drives), backup tapes,
printed materials.`}</p>
    <p>{`All Data Migrators employees and affiliates must comply with this
policy.`}</p>
    <h2>{`Policy`}</h2>
    <h3>{`Technology Equipment Disposal`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`When Technology assets have reached the end of their useful
life they should be sent to the Equipment Disposal Team
office for proper disposal.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The Equipment Disposal Team will securely erase all
storage mediums in accordance with current industry best
practices.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`All data including, all files and licensed software shall be
removed from equipment using disk sanitizing software that
cleans the media overwriting each and every disk sector of
the machine with zero-filled blocks, meeting Department of
Defense standards.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`No computer or technology equipment may be sold to any
individual other than through the processes identified in
this policy (Section 4.2 below).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`No computer equipment should be disposed of via skips,
dumps, landfill etc. Electronic recycling bins may be
periodically placed in locations around Data Migrators.
These can be used to dispose of equipment. The Equipment
Disposal Team will properly remove all data prior to final
disposal.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`All electronic drives must be degaussed or overwritten with
a commercially available disk cleaning program. Hard drives
may also be removed and rendered unreadable (drilling,
crushing or other demolition methods).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Computer Equipment refers to desktop, laptop, tablet or
netbook computers, printers, copiers, monitors, servers,
handheld devices, telephones, cell phones, disc drives or
any storage device, network switches, routers, wireless
access points, batteries, backup tapes, etc.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The Equipment Disposal Team will place a sticker on the
equipment case indicating the disk wipe has been performed.
The sticker will include the date and the initials of the
technician who performed the disk wipe.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Technology equipment with non-functioning memory or storage
technology will have the memory or storage device removed
and it will be physically destroyed.`}</p>
      </li>
    </ol>
    <h3>{`Employee Purchase of Disposed Equipment`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Equipment which is working, but reached the end of its
useful life to Data Migrators, will be made available for
purchase by employees.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`A lottery system will be used to determine who has the
opportunity to purchase available equipment.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`All equipment purchases must go through the lottery process.
Employees cannot purchase their office computer directly or
“reserve” a system. This ensures that all employees have an
equal chance of obtaining equipment.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Finance and Information Technology will determine an
appropriate cost for each item.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`All purchases are final. No warranty or support will be
provided with any equipment sold.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Any equipment not in working order or remaining from the
lottery process will be donated or disposed of according to
current environmental guidelines. Information`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Technology has contracted with several organizations to
donate or properly dispose of outdated technology assets.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Prior to leaving Data Migrators premises, all equipment
must be removed from the Information Technology inventory
system.`}</p>
      </li>
    </ol>
    <h2>{`Policy Compliance`}</h2>
    <h3>{`Compliance Measurement`}</h3>
    <p>{`The Infosec team will verify compliance to this policy through various
methods, including but not limited to, business tool reports, internal
and external audits, and feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec Team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <p>{`None.`}</p>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`None.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      